

export default {
    components: {
    },
    activated() {
        this.mode = localStorage['last_mode'] ?? (this.U.isSeller ? 'seller' : 'customer');
        this.loadData();
    },
    methods: {

        loadData() {

            let t = this;

            axios.get('user/getStats').then(res => {
                if (res.data.status == 1) {
                    t.stats = res.data.options.stats;
                    let b = Number(t.stats.balance).toString();
                    let arr = b.split('.');
                    t.stats.balance1 = arr[0];
                    if (arr.length > 1)
                        t.stats.balance2 = arr[1];

                    if (t.mode == 'seller')
                        t.initChart(t.stats.income_7days);
                }
            });

        },

        setRole(role) {
            if (this.mode != role) {
                this.mode = role;
                localStorage['last_mode'] = role;
                if (this.mode == 'seller')
                    this.initChart();
            }
        },

        initChart(_data) {
            let t = this;

            setTimeout(() => {
                var canvas = document.getElementById("canvas");

                if (!canvas) {
                    t.initChart();
                    return;
                }

                // Apply multiply blend when drawing datasets
                var multiply = {
                    beforeDatasetsDraw: function (chart, options, el) {
                        chart.ctx.globalCompositeOperation = 'multiply';
                    },
                    afterDatasetsDraw: function (chart, options) {
                        chart.ctx.globalCompositeOperation = 'source-over';
                    },
                };

                // Gradient color - previous week
                var gradientPrevWeek = canvas.getContext('2d').createLinearGradient(0, 0, 0, 150);
                gradientPrevWeek.addColorStop(0, '#FF55B8');
                gradientPrevWeek.addColorStop(1, '#FF8787');

                var labels = [], values = [];
                for (var i = 0; i < _data.length; i++) {
                    var _item = _data[i];
                    labels.push(_item.date);
                    values.push(_item.value + 10);
                }

                var config = {
                    type: 'line',
                    data: {
                        labels: labels,
                        datasets: [{
                            label: '',
                            data: values,
                            backgroundColor: gradientPrevWeek,
                            borderColor: 'transparent',
                            pointBackgroundColor: '#FFFFFF',
                            pointBorderColor: '#FFFFFF',
                            lineTension: 0.40,
                        }]
                    },
                    options: {
                        layout: {
                            padding: {
                                top: 40
                            }
                        },
                        elements: {
                            point: {
                                radius: 0,
                                hitRadius: 5,
                                hoverRadius: 5
                            }
                        },
                        legend: {
                            display: false,
                        },
                        scales: {
                            xAxes: [{
                                display: false,
                            }],
                            yAxes: [{
                                display: false,
                                ticks: {
                                    beginAtZero: true,
                                },
                            }]
                        }
                    },
                    plugins: [multiply],
                };

                window.chart = new Chart(canvas, config);

                $('.axis .tick:last-of-type').addClass('active');
            }, 500);
        },

        showProfileEditor() {
            this.profileForm.name = this.U.name;
            this.openModal('edit-profile');
        },

        openModal(name) {
            this.$parent.disabledScroll = true;
            this.modal = name;
        },

        closeModal() {
            this.modal = '';
            this.$parent.disabledScroll = false;
        },

        startUpload() {

            let t = this;

            ImageUploader.onSelectImage = (file) => {

                file = event.target['files'][0];

                var blobUrl = getObjectURL(file);

                const image = document.getElementById('cropperImg');

                image.src = blobUrl;

                if (t.cropper) {
                    t.cropper.destroy();
                    t.cropper = null;
                }

                image.onload = () => {
                    t.cropper = new Cropper(image, {
                        aspectRatio: 1,
                        autoCropArea: 1,
                        crop(event) {
                        },
                    });
                    $('.cropper-item').show();
                }

                event.target['value'] = '';


            };

            ImageUploader.openSelector();
        },

        saveProfile() {
            let t = this;

            let f = t.profileForm;

            if (!f.name) {
                t.profileMsg = "请输入您的昵称";
                return;
            }

            if (t.busying)
                return;

            t.busying = true;

            function _save() {
                axios.post('user/saveProfile', f).then(res => {
                    t.busying = false;

                    if (res.data.status == 1) {
                        let user = res.data.options.item;
                        let U = t.U;
                        if (user.avatar_url)
                            U.avatar_url = user.avatar_url;
                        U.name = user.name;
                        S.set('_u', U);
                        t.closeModal();
                    }
                });
            }

            if (t.profileForm.blob_avatar) {
                let fd = new FormData();
                fd.append('image', t.avatarBlobDict[t.profileForm.blob_avatar]);
                axios.post('user/saveAvatar', fd, { headers: { 'Content-Type': 'multipart/form-data' } }).then(res => {
                    if (res.data.status == 1) {
                        t.profileForm.avatar_url = res.data.options.img_url;
                        _save();
                    }
                });

            }
            else {
                _save();
            }



        },

        goPage(page) {
            this.$router.push(page);
        },

        closeCropper() {
            $('.cropper-item').hide();
        },

        confirmCropper() {

            let t = this;

            t.cropper.getCroppedCanvas({
                minHeight: 80,
                minWdith: 80,
                maxHeight: 300,
                maxWdith: 300,
                fillColor: '#fff',
            }).toBlob((blob) => {
                let blobUrl = getObjectURL(blob);
                t.profileForm.blob_avatar = blobUrl;
                t.avatarBlobDict[blobUrl] = blob;
                t.closeCropper();
            });
        },
    },
    data() {
        return {
            mode: 'customer',
            modal: '',
            profileMsg: "",
            profileForm: {
                'blob_avatar': '',
                'name': ''
            },
            stats: {
                'shipping': 0,
                'returning': 0,
                'coupon': 0,
                'order': 0,
                'member': 0,
                'balance': 0,
                'balance1': 0,
                'balance2': 0,
            },

            avatarBlobDict: {},

            cropper: null,
        };
    },
};
