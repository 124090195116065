import Vue from 'vue'
import Router from 'vue-router'

import Home from './home/home'
import Cart from './cart/cart'
import Orders from './orders/orders'
import User from './user/user'
import List from './list/list'
import Address from './address/address'
import Checkout from './checkout/checkout'
import Favorite from './favorite/favorite'
import Invite from './invite/invite'
import Letter from './letter/letter'
import Item from './item/item'
import Login from './login/login'
import Member from './member/member'
import Mycoupon from './mycoupon/mycoupon'
import Order from './order/order'
import Page from './page/page'
import Promotion from './promotion/promotion'
import Wallet from './wallet/wallet'
import Paymentsuccess from './paymentsuccess/paymentsuccess'
import Faq from './faq/faq'
import Room from './room/room'
import Welcome from './welcome/welcome'
import Adopet from './adopet/adopet'


import './style.css'

Vue.use(Router)
export default new Router({
  mode: 'history',
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      window.lastPosition = savedPosition; 
      return savedPosition
    } else {
      window.lastPosition = {
        x: 0,
        y: 0
      }; 
      return {
        x: 0,
        y: 0
      }
    }
  },
  routes: [{
      path: '',
      redirect: '/home'
    },
    {
      name: 'home',
      path: '/home',
      component: Home,
    },
    {
      name: 'cart',
      path: '/cart',
      component: Cart,
    },
    {
      name: 'orders',
      path: '/orders',
      component: Orders,
    },
    {
      name: 'user',
      path: '/user',
      component: User,
    },
    {
      name: 'category',
      path: '/category',
      component: List,
    },
    {
      name: 'activity',
      path: '/activity',
      component: List,
    },
    {
      name: 'new_arrivals',
      path: '/new_arrivals',
      component: List,
    },
    {
      name: 'address',
      path: '/address',
      component: Address,
    },
    {
      name: 'checkout',
      path: '/checkout',
      component: Checkout,
    },
    {
      name: 'favorite',
      path: '/favorite',
      component: Favorite,
    },
    {
      name: 'invite',
      path: '/invite',
      component: Invite,
    },
    {
      name: 'letter',
      path: '/letter',
      component: Letter,
    },
    {
      name: 'goods',
      path: '/goods',
      component: Item,
    },
    {
      name: 'member',
      path: '/member',
      component: Member,
    },
    {
      name: 'mycoupon',
      path: '/mycoupon',
      component: Mycoupon,
    },
    {
      name: 'order',
      path: '/order',
      component: Order,
    },
    {
      name: 'page',
      path: '/page',
      component: Page,
    },
    {
      name: 'promotion',
      path: '/promotion',
      component: Promotion,
    },
    {
      name: 'wallet',
      path: '/wallet',
      component: Wallet,
    },
    {
      name: 'login',
      path: '/login',
      component: Login,
    },
    {
      name: 'paymentsuccess',
      path: '/paymentsuccess',
      component: Paymentsuccess,
    },
    {
      name: 'faq',
      path: '/faq',
      component: Faq,
    },
    {
      name: 'welcome',
      path: '/welcome',
      component: Welcome,
    },
    {
      name: 'room',
      path: '/room',
      component: Room,
    },
    {
      name: 'adopet',
      path: '/adopet',
      component: Adopet,
    },
    // {
    //   name: 'index',
    //   path: '',
    //   component: Index,
    //   children: [
    //   ]
    // },

  ],
})