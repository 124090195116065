

import { areaList } from '@vant/area-data';

export default {
    name: "checkout",
    created() {

        let t = this;

        if (B.paymentPage)
            t.list.item = B.paymentPage.items;
        else
            t.list.item = S.get('cart_items');

        if (!t.list.item || t.list.item.length == 0) {
            t.$router.replace('cart');
        }
        else {

            let i = 0;

            t.list.item.forEach(item => {
                item._i = i;
                item.coupon = null;
                i++;
            });

            t.getLocation();

            t.calcTotal();

            t.getAddressList();

        }

    },
    mounted() {
        this.$refs.trans.ready = true;
    },
    methods: {

        calcTotal() {

            let t = this;

            let goodsData = pick(t.list.item, ['id', 'qty', 'label_ids', '_i']);

            axios.post('user/calcTotal', { 'json_items': goodsData }).then(res => {

                if (res.data.status == 1) {

                    t.total = res.data.options.total;

                    let usedCoupons = res.data.options.used_coupons;

                    if (usedCoupons) {

                        let dict = listToDict(t.list.item, '_i');

                        if (dict)
                            for (const _i in usedCoupons) {
                                if (dict[_i])
                                    dict[_i].coupon = usedCoupons[_i];
                            }

                    }
                }

            });

        },

        getLocation() {

            let t = this;

            if (localStorage['pct']) {
                t.pct = JSON.parse(localStorage['pct']);
                t.addressForm.pct = [t.pct.province, t.pct.city, t.pct.town].join('');
            }
            else {
                axios.get('getLocation').then(res => {
                    if (res.data.status == 1) {
                        t.pct = res.data.options;
                        localStorage['pct'] = JSON.stringify(t.pct);
                        t.addressForm.pct = [t.pct.province, t.pct.city, t.pct.town].join('');
                    }
                });
            }

        },

        getAddressList() {
            let t = this;

            axios.get('user/getAddressList').then(res => {
                if (res.data.status == 1) {
                    t.list.address = res.data.options.items;
                    if (t.list.address.length > 0) {
                        t.list.address.forEach(item => {
                            if (item.is_first == 1)
                                t.selectedAddress = item;
                        });
                        if (!t.selectedAddress)
                            t.selectedAddress = t.list.address[0];
                    } else {
                        t.showNewAddressForm = true;
                        t.addressForm.is_first = true;
                    }
                }
            });

        },

        saveAddress() {

            let t = this;

            let f = t.addressForm;

            if (!f.name) {
                t.addressMsg = "请输入收件人名称";
                return;
            }

            if (!/^1\d{10}$/.test(f.tel)) {
                t.addressMsg = "请输入手机号(11位数字)";
                return;
            }

            if (!f.street) {
                t.addressMsg = "请输入详细地址";
                return;
            }

            if (!f.pct) {
                t.addressMsg = "请选择所在地";
                return;
            }

            if (t.busying)
                return;

            t.busying = true;

            axios.post('user/saveAddress', f).then(res => {

                t.busying = false;

                if (res.data.status == 1) {

                    t.selectedAddress = res.data.options.item;

                    t.list.address.unshift(t.selectedAddress);

                    t.showNewAddressForm = false;

                    t.addressForm = {
                        'name': '',
                        'tel': '',
                        'pct': '',
                        'street': '',
                        'is_first': false,
                    };

                }

            });

        },

        selectAddress(item) {
            this.selectedAddress = item;
        },

        launchPayment() {

            let t = this;

            if (t.submitted)
                return;

            if (!t.selectedAddress) {
                A.show('请选择一个收货地址', '', 'warning');
                return;
            }

            let goodsData = pick(t.list.item, ['id', 'qty', 'label_ids', '_i']);

            let f = {
                'json_items': goodsData,
                'address_id': t.selectedAddress.id
            };

            if (t.busying)
                return;

            t.busying = true;

            axios.post('user/order/submit', f).then(res => {

                t.busying = false;

                if (res.data.status == 1) {

                    t.order = res.data.options.item;

                    t.submitted = true;

                    if (B.paymentPage && B.paymentPage.from == 'cart') {
                        S.remove('cart_items');
                    }

                    wx.miniProgram.navigateTo({
                        url: '/pages/payment/payment?oid=' + t.order.id
                    });

                    t.startPollingForPaymentResult();
                }
            });

        },

        startPollingForPaymentResult() {

            let t = this;

            axios.post('user/order/status/' + t.order.id).then(res => {

                if (res.data.status == 1) {

                    if (res.data.options.item.status == 2)
                        t.$router.replace('/paymentsuccess?oid=' + t.order.id);
                    else
                        setTimeout(() => { t.startPollingForPaymentResult() }, 1000);

                }

            });

        },

        selectArea(e) {
            let t = this;
            t.showAreaSelector = false;
            if (e[0].name == e[1].name)
                e[0].name = '';
            t.addressForm.pct = [e[0].name, e[1].name, e[2].name].join('');

        },

        hideAreaSelector() {
            this.showAreaSelector = false;
        }
    },
    data() {
        return {
            order: null,
            showNewAddressForm: false,
            submitted: false,
            total: {
                freight: 0,
                amount: 0,
                payment_amount: 0,
                coupon_amount: 0
            },
            selectedAddress: null,
            addressMsg: "",
            pct: null,
            addressForm: {
                'name': '',
                'tel': '',
                'pct': '',
                'street': '',
                'is_first': false,
            },
            list: {
                item: [],
                address: []
            },
            showAreaSelector: false,
            areaData: areaList,
        };
    },
};

