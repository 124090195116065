

export default {
  name: "Trans",
  props: {
    animation: {
      type: String,
      required: false,
      default: "slideInLeft"
    },
  },
  mounted() { },
  created() {
    let t = this;
    setTimeout(() => {
      t.ready = true;
    }, 50);
  },
  activated: function (el) {
    this.ready = true;
    this.fixScroll();
  },
  deactivated() {
    this.ready = false;
  },
  methods: {

    fixScroll() {
      setTimeout(() => {
        if (window.lastPosition && window.lastPosition.y > window.scrollY) {
          window.scrollTo(0, window.lastPosition.y);
          window.lastPosition = null;
        }
      }, 50);
    }
  },
  data() {
    return {
      ready: false,
    };
  },
};
