


export default {
    created() {

        let t = this;

        t.getAddressList();

    },

    activated() {
        if (B.addressPage) {
            if (B.addressPage.from == 'order')
                this.selectMode = true;
        }
    },
    deactivated() {
        this.selectMode = false;
        delete B.addressPage;
    },
    methods: {
        getLocation() {

            let t = this;

            if (localStorage['pct']) {
                t.pct = JSON.parse(localStorage['pct']);
                t.addressForm.pct = [t.pct.province, t.pct.city, t.pct.town].join(' ');
            }
            else {
                axios.get('getLocation').then(res => {
                    if (res.data.status == 1) {
                        t.pct = res.data.options;
                        localStorage['pct'] = JSON.stringify(t.pct);
                        t.addressForm.pct = [t.pct.province, t.pct.city, t.pct.town].join(' ');
                    }
                });
            }

        },

        showPCTSelector() {

            let t = this;

            let pct = t.pct;

            let oneLevelId, twoLevelId, threeLevelId;

            if (pct) {
                let dict_p = listToDict2(iosProvinces, 'value', 'id');
                let dict_c = listToDict2(iosCitys, 'value', 'id');
                let dict_t = listToDict2(iosCountys, 'value', 'id');

                oneLevelId = dict_p[pct.province];
                twoLevelId = dict_c[pct.city];
                threeLevelId = dict_t[pct.town];
            }

            var iosSelect = new IosSelect(3,
                [iosProvinces, iosCitys, iosCountys],
                {
                    title: '地址选择',
                    itemHeight: 35,
                    relation: [1, 1],
                    oneLevelId: oneLevelId,
                    twoLevelId: twoLevelId,
                    threeLevelId: threeLevelId,
                    callback: function (o1, o2, o3) {
                        let arr = [o1, o2, o3];
                        t.addressForm.pct = pluck(arr, 'value').join(' ');
                    }
                });
        },

        getAddressList() {
            let t = this;

            axios.get('user/getAddressList').then(res => {
                if (res.data.status == 1) {
                    t.list.address = res.data.options.items;
                    if (t.list.address.length > 0) {
                    } else {
                        t.showNewAddressForm = true;
                        t.addressForm.is_first = true;
                    }
                }
            });

        },

        saveAddress() {

            let t = this;

            let f = t.addressForm;

            if (!f.name) {
                t.addressMsg = "请输入收件人名称";
                return;
            }

            if (!/^1\d{10}$/.test(f.tel)) {
                t.addressMsg = "请输入手机号(11位数字)";
                return;
            }

            if (!f.street) {
                t.addressMsg = "请输入详细地址";
                return;
            }

            if (!f.pct) {
                t.addressMsg = "请选择所在地";
                return;
            }

            if (t.busying)
                return;

            t.busying = true;

            axios.post('user/saveAddress', f).then(res => {

                t.busying = false;

                if (res.data.status == 1) {

                    if (f.id)
                        mirror(f, t.selectedAddress);
                    else
                        t.list.address.unshift(res.data.options.item);

                    if (f.is_first) {
                        t.list.address.forEach(address => {
                            address.is_first = false;
                        });

                        if (t.selectedAddress)
                            t.selectedAddress.is_first = true;
                        else
                            res.data.options.item.is_first = true;
                    }

                    t.showNewAddressForm = false;

                    t.addressForm = {
                        'name': '',
                        'tel': '',
                        'pct': '',
                        'street': '',
                        'is_first': false,
                    };

                }

            });

        },

        selectAddress(item) {
            if (this.selectMode) {
                B.addressPage.onSelect(item);
                this.$router.back(-1);
                return;
            }
            this.selectedAddress = item;
        },

        editAddress(item) {
            this.showNewAddressForm = true;
            mirror(item, this.addressForm);
        },

        newAddress() {
            this.showNewAddressForm = true;
            this.addressForm = {
                'name': '',
                'tel': '',
                'pct': '',
                'street': '',
                'is_first': false,
            };
            this.getLocation();
        },

        back() {
            A.safety('操作不会被保存','warning').then(res=>{
                if (res.isConfirmed) {
                    this.showNewAddressForm = false;
                }
            });
        },

        removeAddress(item) {

            let t = this;

            if (t.busying)
                return;

            t.busying = true;

            A.safety('确定要移除该地址吗？','warning').then(res=>{
                t.busying = false;

                if (res.isConfirmed) {
                    axios.get('user/removeAddress/' + item.id).then(res => {

                        if (res.data.status == 1) {
                            t.list.address.remove(item);
                            t.selectedAddress = null;
                        }
                    });


                }
            });
        },

    },
    data() {
        return {
            selectMode: false,
            showNewAddressForm: false,
            selectedAddress: null,
            addressMsg: "",
            pct: null,
            addressForm: {
                'name': '',
                'tel': '',
                'pct': '',
                'street': '',
                'is_first': false,
            },
            list: {
                address: []
            }
        };
    },
};
