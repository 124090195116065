
import AppNav from "./components/nav";
import Vue from 'vue'
export default {
  name: "Index",
  components: {
    AppNav,
  },
  data() {
    return {
      disabledScroll: false
    };
  },
  methods: {
    afterLogin() {
      axios.defaults.headers.common["Token"] = this.U.token;
      axios.defaults.headers.common["OpenID"] = this.U.open_id;
    }
  },
  created() {

    var t = this;

    Vue.prototype.startUrl = location.href;

    axios.defaults.baseURL = 'https://i.shan-huan.com/u/';

    if(/localhost/.test(location.href)){
      axios.defaults.baseURL = 'http://localhost/mll/api/public/u/';
    }

    Vue.prototype.busying = false;

    Vue.prototype.toggle = {
      expandedNav: true
    };

    Vue.prototype.U = {
      name: '',
      avatar_url: '',
      token: '',
      id: '',
      open_id: '',
      company_id: 0
    };


    if (S.get('_u')) {
      Vue.prototype.U = S.get('_u');
      t.afterLogin();
    }

    let p = getURLParams();

    if (p.open_id) {
      axios.post('account/signInByOpenId', { 'open_id': p.open_id }).then(res => {
        if (res.data.status == 1) {
          let U = res.data.options.item;
          mirror(U, t.U);
          S.set('_u', U);
          t.afterLogin();
        }
      });
    }

  },
  mounted() {
    let t = this;
  }

};
