

export default {

    components: {},
    mounted() {
        $(function () {
            var swiper = new Swiper(".gallery-slider", {
                effect: "cards",
                grabCursor: true,
            });
        });
    },
    methods: {
    },
    data() {
        return {
        };
    },
};
