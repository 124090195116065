

export default {
    created() {

    },
    activated() {


        this.loadData();
    },
    methods: {


        loadData() {
            let t = this;

            axios.get('user/getFavoritedList').then(res => {
                if (res.data.status == 1) {
                    t.list.goods = res.data.options.items;
                }
            });
        },

    },
    data() {
        return {
            list: {
                goods: []
            },
            search: {
                text: ''
            }
        };
    },
    computed: {
        filteredGoods() {
            let t = this;

            let arr = [];

            t.list.goods.forEach(g => {

                let go = true;

                if (t.search.text) {
                    go = g.name.indexOf(t.search.text.trim()) > -1;
                }

                if (go)
                    arr.push(g);
            });

            return arr;
        }
    }
};
