

import SortBadge from "../components/sort-badge";

export default {

    components: { SortBadge },

    activated() {
        this.loadData();
    },
    
    methods: {
        loadData() {
            let t = this;

            axios.get('user/getUsableCoupons').then(res => {
                if (res.data.status == 1) {
                    t.list.goods = res.data.options.items;
                }
            });

        },

    },

    data() {
        return {
            list: {
                goods: []
            },
            sort: {
                amount: 2,
                price: 0,
            },
        };
    },
};
