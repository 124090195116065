
import goods from '../components/goods.vue';


export default {
    components: { goods },

    activated() {
        this.loadData();
    },
    methods: {
        loadData() {

            let t = this;

            axios.get('getData4Home').then(res => {
                if (res.data.status == 1) {
                    t.list.banner = res.data.options.banners;
                    t.list.category = res.data.options.categories;
                    t.list.activity = res.data.options.activities;
                    t.list.newArrival = res.data.options.new_arrivals;
                    t.isReady = true;
                }
            });


        },

        tapBanner(b) {
            this.$router.push('goods?id=1');
        },

        goCategory(c) {
            this.$router.push('category?id=' + c.id);
        },

        goActivity(a) {
            this.$router.push('activity?id=' + a.id);
        },

        goNewArrivals() {
            this.$router.push('new_arrivals');
        }

    },
    data() {
        return {
            isReady: false,
            list: {
                category: [],
                activity: [],
                newArrival: [],
                banner: []
            },
        };
    },
};
