

export default {
  name: "Action",
  props: {
    value: 0
  },
  mounted() { },
  created() {
  },
  methods: {
  },
  data() {
    return {
    };
  },
};
