
export default {

    activated() {

        let t = this;

        t.list.item = [];

        let cartItems = JSON.parse(localStorage['cart_items'] ? localStorage['cart_items'] : '[]');

        for (let i = 0; i < cartItems.length; i++) {
            if (cartItems[i].selected == undefined)
                cartItems[i].selected = true;
        }

        t.list.item = cartItems;

        t.calcTotal();
    },

    deactivated() {
    },

    methods: {
        selectAllToggle() {
            let t = this;

            t.allIsChecked = !t.allIsChecked;
            for (let i = 0; i < t.list.item.length; i++) {
                let item = t.list.item[i];
                item.selected = t.allIsChecked;
            }
            t.calcTotal();
        },
        selectToggle(item) {
            item.selected = !item.selected;
            this.calcTotal();
        },

        setNum(item, num) {
            item.qty = Number(item.qty) + num;
            if (item.qty < 1)
                item.qty = 1;

            event.stopPropagation();
            this.calcTotal();
        },

        removeItems() {

            let t = this;

            let arr = [];

            for (let i = 0; i < t.list.item.length; i++) {
                let item = t.list.item[i];
                if (item.selected)
                    arr.push(item);
            }

            if (arr.length > 0) {

                A.safety('确定要移除选中的商品吗？', 'warning').then(res => {

                    if (res.isConfirmed) {
                        for (let i = 0; i < arr.length; i++) {
                            t.list.item.remove(arr[i]);
                        }
                        t.calcTotal();
                    }
                });
            }
        },

        calcTotal() {

            let t = this;

            let amount = 0;

            for (let i = 0; i < t.list.item.length; i++) {
                let item = t.list.item[i];
                if (item.selected)
                    amount += Number((item.price * item.qty).toFixed(1));
            }

            t.amount = amount;

            localStorage['cart_items'] = JSON.stringify(this.list.item);
        },

        goCheckout() {

            B.paymentPage = {
                from: 'cart',
                items: filter(this.list.item, item => item.selected)
            };

            this.$router.push('/checkout');
        }
    },
    data() {
        return {
            list: {
                item: [],
            },
            allIsChecked: true,
            amount: 0
        };
    },
    computed: {
        left_items: (t) => {
            let arr = [];
            for (let i = 0; i < t.list.item.length; i++) {
                if (i % 2 == 0) {
                    arr.push(t.list.item[i]);
                }
            }
            return arr;
        },
        right_items: (t) => {
            let arr = [];
            for (let i = 0; i < t.list.item.length; i++) {
                if (i % 2 != 0) {
                    arr.push(t.list.item[i]);
                }
            }
            return arr;
        }
    }
};
