

export default {
  name: "Nav",
  props: {},
  mounted() { },
  created() {
    var t = this;

    var reg = /\/(home|orders|cart|user)/i;
    var t = this;

    t.$router.beforeEach((to, from, next) => {

      var r = reg.exec(to.path);

      if (r && r.length > 0) {
        t.page = r[1];
        t.showNav = true;
      }
      else
        t.showNav = false;

      next();
    });

    var r = reg.exec(location.pathname);

    if (r && r.length > 0) {
      t.page = r[1];
      t.showNav = true;
    }
    else
      t.showNav = false;

    // if (localStorage.getItem("onlineUser"))
    //   t.onlineUser = JSON.parse(localStorage.getItem("onlineUser"));
  },
  methods: {
    go(page) {

      if (page == this.page) return;

      this.page = page;

      this.$router.replace(page);

    }
  },
  data() {
    return {
      page: "",
      showNav: false,
      onlineUser: {},
    };
  },
  components: {
  },
};
