

export default {
    components: {},
    created() {
        this.loadData();
    },
    methods: {
        loadData() {

            let t = this;

            let p = getURLParams();

            t.rate = p.rate ? Number(p.rate) : 50;
            t.limit = p.limit ? Number(p.limit) : 5000;

            axios.get('getInviterInfo/' + getURLParams().iid).then(res => {
                if (res.data.status == 1) {
                    t.inviterInfo = res.data.options.item;
                }
            });

        },

        acceptInvitation() {
            let t = this;

            if (t.busying)
                return;

            t.busying = true;

            axios.post('member/add', { percent: t.rate, max: t.limit, leader_id: t.inviterInfo.id }).then(res => {

                t.busying = false;

                if (res.data.status == 1 || res.data.status == 2) {
                    A.show('您已成功与 <b>团长[' + t.inviterInfo.name + ']</b> 缔结联系。', '💐💐 欢迎加入').then(() => {
                        t.$router.replace('/block');
                    });
                }
                else
                    A.err(res.data.msg);
            });

        }

    },
    data() {
        return {
            inviterInfo: null,
            rate: 50,
            income: 100,
            limit: 5000
        };
    },
};
