

export default {

    created() {
        this.dict.dateRange = listToDict(this.list.dateRange);
        this.dateRange = this.list.dateRange[0];
        this.loadData();
        this.loadLogs();
    },
    components: {},
    methods: {
        loadData() {
            let t = this;

            axios.get('user/getIncomeStats').then(res => {
                if (res.data.status == 1) {
                    t.stats = res.data.options.stats;
                }
            });

        },

        loadLogs() {
            let t = this;
            let params = {
                type: t.type,
                days: t.dateRange.days
            };
            axios.get('user/getAmountLog', { params }).then(res => {
                if (res.data.status == 1) {
                    let logs = res.data.options.items;
                    logs.forEach(log => {
                        let dayDiff = Math.ceil((new Date() - new Date(log.created_at)) / (1000 * 3600 * 24));
                        if (log.type == 100) {
                            log.title = '订单佣金';
                            log.desc = '查看订单' + '<span class="sup">' + (15 - dayDiff) + '天后解冻</span>';
                        }
                        else if (log.type == 101) {
                            log.title = '佣金抽成';
                            log.desc = '来自: ' + log.var1 + '<span class="sup">' + (15 - dayDiff) + '天后解冻</span>';
                        }
                        else if (log.type == 200) {
                            log.title = '余额提现';
                            if (log.com_status == 1)
                                log.desc = '正在提现';
                            else if (log.com_status == 2)
                                log.desc = '提现成功';
                            else if (log.com_status == 3)
                                log.desc = '提现失败' + '<span class="sup">原因: ' + log.var1 + '</span>';
                        }
                    });
                    t.list.log = logs;
                }
            });

        },


        setType(_v) {

            if (_v != this.type) {
                this.type = _v;
                this.loadLogs();
            }

        },

        applyWithdrawal() {
            let t = this;

            let f = t.withdrawalForm;

            if (t.busying)
                return;

            t.busying = true;


            if (f.amount < 10) {
                t.withdrawalMsg = "提现金额不得小于10元";
                return;
            }

            if (f.amount > t.stats.balance) {
                t.withdrawalMsg = "您的可提现金额为 ￥" + t.stats.balance;
                return;
            }

            t.withdrawalMsg = "";

            axios.post('user/applyWithdrawal', f).then(res => {
                t.busying = false;

                if (res.data.status == 1) {
                    t.stats.balance -= f.amount;
                    f.amount = '';
                    t.loadLogs();
                    A.show('提现审核的时间为工作日的10点~18点，请耐心等候。', '提现申请成功', 'success');
                }
            });

        },

        showDateRangePicker() {

            let t = this;

            var iosSelect = new IosSelect(1,
                [
                    t.list.dateRange
                ],
                {
                    title: '时间范围',
                    itemHeight: 50,
                    itemShowCount: 4,
                    oneLevelId: 1,
                    callback: function (o1) {
                        t.dateRange = t.dict.dateRange[o1.id];
                        t.loadLogs();
                    }
                });
        }

    },
    data() {
        return {
            stats: null,
            type: 'all',
            dateRange: null,
            list: {
                log: [],
                dateRange: [
                    { id: 1, value: '近7天', days: 7 },
                    { id: 2, value: '近15天', days: 15 },
                    { id: 3, value: '近30天', days: 30 },
                    { id: 4, value: '近90天', days: 90 },
                    { id: 5, value: '近180天', days: 180 },
                    { id: 6, value: '近365天', days: 365 }
                ]
            },
            dict: {
                dateRange: {}
            },
            withdrawalForm: {
                amount: ''
            },
            withdrawalMsg: ''
        };
    },
};
