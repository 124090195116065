

export default {

    activated() {

        let t = this;

        let n = t.$router.currentRoute.name;

        let p = getURLParams();

        t.inCategoryPage = false;;

        if (n == 'activity') {
            t.loadTopCategory();
        }
        else if (n == 'category') {
            t.inCategoryPage = true;
            t.cate_id = p.id;
            t.loadSecondCategory(p.id);
        }
        else if (n == 'new_arrival') {
            t.loadTopCategory();
            // t.firstName = '新品上架';
        }

        this.loadData();
    },

    methods: {

        loadData() {
            let t = this;

            let n = t.$router.currentRoute.name;

            let p = getURLParams();

            let params = { _order: t.makeSort(t.sort) };

            if (n == 'activity') {
                params.activity_id = p.id;
            }
            else if (n == 'new_arrival') {
                params.is_new = 1;
            }

            if (t.cate_id)
                params.cate_id = t.cate_id;

            axios.get('goods', { params: params }).then(res => {
                if (res.data.status == 1) {
                    t.list.goods = res.data.options.items;
                    if (res.data.options.activity) {
                        // t.firstName = res.data.options.activity.name;
                        document.title = res.data.options.activity.name;
                    }
                }
            });

        },

        loadTopCategory() {

            let t = this;

            axios.get('category').then(res => {
                if (res.data.status == 1) {
                    t.list.category = res.data.options.items;
                }
            });
        },


        loadSecondCategory(cate_id) {

            let t = this;

            axios.get('category', { params: { parent_id: cate_id } }).then(res => {
                if (res.data.status == 1) {
                    t.list.category = res.data.options.items;
                    if (res.data.options.top_cate) {
                        t.firstName = res.data.options.top_cate.name;
                    }
                }
            });
        },


        back() {
            this.$router.back();
        },

        favoriteToggle(item) {

            item.favorited = !item.favorited;

            setTimeout(() => {
                $('.like-button:focus').blur();
            }, 800);

            axios.post('user/favoriteToggle', { goods_id: item.id }).then(res => {
                if (res.data.status == 1) {

                }
            });

        },

        selectCategory(cate_id) {
            let t = this;

            if (cate_id == t.cate_id)
                return;

            t.cate_id = cate_id;

            t.loadData();
        }

    },

    data() {
        return {
            active: 1,
            list: {
                goods: [],
                category: []
            },
            sort: {
                count_visited: 2,
                reward: 0,
                price: 0,
            },
            fullscreen: true,
            showCategory: true,
            search: {
                text: '',
            },
            cate_id: 0,
            inCategoryPage: false,
            firstName: '全部'
        };
    },

    computed: {
        filteredGoods() {
            let t = this;

            let arr = [];

            t.list.goods.forEach(g => {

                let go = true;

                if (t.search.text) {
                    go = g.name.indexOf(t.search.text.trim()) > -1;
                }

                if (go)
                    arr.push(g);
            });

            return arr;
        }
    }
};
