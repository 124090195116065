

export default {
    name: "paymentsuccess",
    created() {

        let t = this;

        axios.get('user/order/' + getURLParams().oid).then(res => {
            if (res.data.status == 1) {
                t.order = res.data.options.item;
                // t.list.item = t.order.items;
                setTimeout(() => {
                    t.$refs.trans.ready = true;
                }, 100);
            }
        });


    },
    mounted() {

    },
    methods: {
        goOrder() {
            this.$router.replace('/order?id=' + this.order.id);
        },
        goHome() {
            this.$router.replace('/home');
        },
    },
    data() {
        return {
            order: null,
            address: null,
            list: {
                item: [],
            }
        };
    },
};
