
export default {
  created(){
    this.initSharing("它一直在等你，给它一个家","/pages/index/index?path=welcome");
    this.loadData();
  },
  activated() {
    this.initSharing("它一直在等你，给它一个家","/pages/index/index?path=welcome");
  },
  methods: {
    loadData(){
      let t = this;
      
      axios.get('welcomeConfig').then(res => {
          if (res.data.status == 1) {
            t.list.poster = res.data.options.items;
          }
      });
      
    }
  },
  data() {
    return {
      view: "",
      errMsg: "",
      busying: false,
      list:{
        poster:[]
      }
    };
  },
};
