
export default {
  created() {
    this.initSharing("广州领养小屋","/pages/index/index?path=room");
    this.loadData();
  },
  activated() {
    this.initSharing("广州领养小屋","/pages/index/index?path=room");
  },
  methods: {
    goShare(){
      if(window.wx){

        wx.miniProgram.redirectTo({
                url: '/pages/sharing/sharing?showGuide=1'
            });
      }
    },
    loadData() {
      let t = this;

      axios.get("pet/item").then((res) => {
        if (res.data.status == 1) {
          let arr = res.data.options.items;
          arr.forEach((item) => {
            item.append_arr = [];
            if (item.append) item.append_arr = item.append.split(",");
          });
          t.list.pet = arr;
        }
      });

      axios.get("pet/category").then((res) => {
        if (res.data.status == 1) {
          let arr = res.data.options.items;
          t.list.category = arr;
        }
      });
    },
    selectCategory(item) {
      this.search.category = item;
    },
    goAdoPet(item){
      this.$router.push('/adopet?id='+ item.id);
    }
  },
  data() {
    return {
      view: "",
      errMsg: "",

      busying: false,
      search: {
        keywords: "",
        category: null,
      },
      list: {
        category: [],
        pet: [],
      },
    };
  },
  computed: {
    filteredPet() {
      let t = this;

      let arr = [];

      t.list.pet.forEach((g) => {
        let go = true;

        if (t.search.keywords) {
          go = g.name.indexOf(t.search.keywords.trim()) > -1;
        }

        if (go) arr.push(g);
      });

      return arr;
    },
  },
};
