

export default {
  name: "Action",
  props: {
    enabledBack: Boolean,
    keepExpand: Boolean,
  },
  mounted() { },
  created() {
    window.r = this.$router;
    var t = this;
    t.toggle = this.$root.toggle;
    if (localStorage['action_folded'])
      t.toggle.expandedNav = localStorage['action_folded'] == 1;
  },
  methods: {
    foldToggle() {
      this.toggle.expandedNav = !this.toggle.expandedNav;
      localStorage['action_folded'] = (this.toggle.expandedNav ? 1 : 0);
    },
    back() {
      let t = this;

      if (t.startUrl == location.href) {
        if (t.$router.currentRoute.name == 'order')
          t.$router.replace('/orders');
        else
          t.$router.replace('/home');
      }
      else
        t.$router.go(-1);

    },

    goUser() {
      this.$router.replace('user');
    }
  },
  data() {
    return {
      toggle: null
    };
  },
};
