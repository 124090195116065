

export default {

    components: {},
    methods: {
    },
    data() {
        return {
        };
    },
};
