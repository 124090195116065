


export default {

    methods: {
        invite() {

            let t = this;

            t.initSharing('共同富裕的一小步！', '/pages/index/index?path=letter&params=' + JSON.stringify({ 'iid': t.U.id, 'rate': t.rate, 'limit': t.limit }));

            t.showSharingGuide();
        }
    },
    data() {
        return {
            rate: 50,
            income: 100,
            limit: 5000
        };
    },
};
