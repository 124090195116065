
export default {
  created() {
    this.loadData();
  },
  activated() {
    let t = this;
    if(t.item)
    t.initSharing(item.age + (item.gender == 1 ? '公猫':'母猫')+' ' + item.breed + ' | ' + item.name ,"/pages/index/index?path=" + encodeURIComponent("adopet?id="+item.id));
  },
  methods: {
    loadData() {
      let t = this;

      let p = getURLParams();

      axios.get("pet/item/" + p.id).then((res) => {
        if (res.data.status == 1) {
          let item = res.data.options.item;

          item.img_urls_arr = item.img_urls.split(",");

          item.append_arr = [];

          if (item.append) item.append_arr = item.append.split(",");

          t.initSharing(item.age + (item.gender == 1 ? '公猫':'母猫')+' ' + item.breed + ' | ' + item.name ,"/pages/index/index?path=" + encodeURIComponent("adopet?id="+item.id));

          t.item = item;
        }
      });
    },
    contact(){
      A.show('添加微信号：' + this.item.wechat + '，备注：领养宠物。', '请使用微信联系','info');
    }
  },
  data() {
    return {
      item: null,
      view: "",
      errMsg: "",
      busying: false,
    };
  },
};
