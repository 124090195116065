


export default {

    activated() {
        this.loadData();
    },
    methods: {

        loadData() {
            let t = this;

            axios.get('goods/' + getURLParams().id).then(res => {
                if (res.data.status == 1) {

                    if (res.data.options.created_coupons)
                        t.list.createdCoupons = res.data.options.created_coupons;

                    t.initItem(res.data.options.item);

                    setTimeout(() => {

                        // t.$refs.trans.ready = true;

                        t.initUI();

                    }, 50);


                }
            });
        },

        initItem(item) {

            let t = this;

            if (item.img_urls)
                item.img_urls = item.img_urls.split(',');

            document.title = item.name;

            item.var_img = item.img_urls[0];
            item.var_price = item.price;
            item.var_stock = -1;
            item.var_coupon_limit = 0;
            item.var_reward = 0;

            for (let i = 0; i < item.options.length; i++) {

                let opt = item.options[i];

                opt.selectedLabel = opt.labels[0];

                for (let j = 0; j < opt.labels.length; j++) {
                    let label = opt.labels[j];
                    label.selected = false;
                }

                opt.labels[0].selected = true;

                if (opt.labels[0].img_url)
                    item.var_img = opt.labels[0].img_url;

            }

            t.item = item;

            t.afterSelect();
        },

        initUI() {
            let t = this;

            setTimeout(() => {

                if (document.getElementById('slider')) {
                    let swiper = new Swiper('#slider', {
                        spaceBetween: 30,
                        effect: 'fade',
                        loop: true,
                        pagination: {
                            el: '.blog-slider__pagination',
                            clickable: true,
                        }
                    });
                }
                else
                    t.initUI();

            }, 100);

        },

        goPromotion() {
            this.$router.push('/promotion?from=item&gid=' + this.item.id);
        },

        startCreateCoupon() {
            this.openModal('create-coupon');
        },

        showCoupons() {
            if (this.list.createdCoupons.length > 0)
                this.openModal('coupons');
            else
                this.openModal('create-coupon');
        },

        showOptions(putInCart) {
            if (putInCart)
                this.nextStep = 'addToCart';
            else
                this.nextStep = 'checkout';

            this.openModal('options');
        },

        showInfo(field) {
            let dict = {
                'guarantee': '产品保障',
                'ship_from': '发货须知',
                'params': '产品参数',
                'brand': '品牌信息'
            };
            this.infoBox.title = dict[field];
            this.infoBox.field = field;
            this.infoBox.content = this.item[field];
            this.openModal('info');
        },

        goNextStep() {
            let t = this;

            let item = t.item;

            let cartItem = {
                'id': item.id,
                'name': item.name,
                'price': item.price,
                'qty': t.qty,
                'img_url': item.img_urls[0]
            };

            if (t.selectedValue) {
                cartItem.label_ids = t.selectedValue.label_ids;
                cartItem.price = t.selectedValue.price;
            }
            if (t.selectedLabels.length > 0) {
                cartItem.labels = pluck(t.selectedLabels, 'name');
            }

            if (this.nextStep == 'addToCart') {

                let cartItems = JSON.parse(localStorage['cart_items'] ? localStorage['cart_items'] : '[]');

                let isNew = true;

                for (let i = 0; i < cartItems.length; i++) {

                    let _item = cartItems[i];

                    if (_item.id == cartItem.id && _item.label_ids == cartItem.label_ids) {
                        _item.qty = Number(_item.qty) + Number(cartItem.qty);
                        isNew = false;
                    }
                }

                if (isNew)
                    cartItems.push(cartItem);

                localStorage['cart_items'] = JSON.stringify(cartItems);

                t.closeModal();

                A.toast('您选购的商品已放入购物车', 1000)

            }
            else if (this.nextStep == 'checkout') {
                B.paymentPage = {
                    from: 'item',
                    items: [cartItem]
                };
                t.$router.push('/checkout');
            }

            t.closeModal();
        },

        openModal(name) {
            this.$parent.disabledScroll = true;
            this.modal = name;
        },

        closeModal() {
            this.modal = '';
            this.$parent.disabledScroll = false;
        },

        setNum(k, v) {
            this[k] = Number(this[k]) + Number(v);
        },

        setNum2(k, v) {
            this.couponForm[k] = Number((Number(this.couponForm[k]) + Number(v)).toFixed(1));

            this.inputOnChange(k);
        },

        selectValue(opt, label) {

            let t = this;

            for (let l in opt.labels) {
                opt.labels[l].selected = false;
            }

            opt.selectedLabel = label;

            label.selected = true;

            if (label.img_url)
                t.item.var_img = label.img_url;

            this.afterSelect();

        },

        inputOnChange(k) {

            let t = this;

            if (k == 'min_amount') {
                if (t.couponForm.min_amount < t.couponForm._min_amount)
                    t.couponForm.min_amount = t.couponForm._min_amount;

                let rate = Math.floor(t.couponForm.min_amount / t.item.var_price);

                t.item.var_coupon_limit = t.item.var_reward * rate;
            }

            if (t.couponForm[k] < 1)
                t.couponForm[k] = 1;

        },

        afterSelect() {

            let t = this;
            let item = t.item;

            let labelIds = [];
            let selectedLabels = [];

            for (let i = 0; i < item.options.length; i++) {

                let opt = item.options[i];

                if (opt.selectedLabel) {
                    labelIds.push(opt.selectedLabel.id);
                    selectedLabels.push(opt.selectedLabel);
                }

            }

            t.selectedLabels = selectedLabels;

            let reward = -1;

            labelIds = labelIds.sort();

            if (labelIds.length == item.options.length) {

                labelIds = labelIds.join(',');

                for (let i = 0; i < item.values.length; i++) {

                    let value = item.values[i];

                    if (value.label_ids == labelIds) {
                        item.var_price = value.price;
                        item.var_stock = value.stock;
                        reward = value.reward;
                        t.selectedValue = value;
                    }

                }

            }

            if (reward == -1)
                reward = item.reward;

            item.var_reward = reward;
            item.var_coupon_limit = reward;

            t.couponForm.min_amount = Number(item.var_price);
            t.couponForm._min_amount = Number(item.var_price);
        },


        createCoupon() {

            let t = this;

            t.couponForm.goods_id = t.item.id;

            axios.post('user/createCoupon', t.couponForm).then(res => {
                if (res.data.status == 1) {
                    t.list.createdCoupons.unshift(res.data.options.item);
                    this.openModal('coupons');
                    A.show('优惠券已创建成功！','操作成功','success');
                }
                else 
                    A.show(res.data.msg,'操作失败','error');
            });

        },
    },
    data() {
        return {
            item: null,
            modal: '',
            nextStep: '',
            qty: 1,
            list: {
                createdCoupons: []
            },
            couponForm: {
                quantity: 1,
                min_amount: 0,
                amount: 1
            },
            selectedValue: null,
            selectedLabels: [],
            infoBox: {
                title: '',
                field: '',
                content: ''
            }
        };
    },
};
