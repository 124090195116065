


export default {
    components: {},
    created() {

    },
    activated() {
        let t = this;
        let p = getURLParams();
        if (p.status)
            t.status = p.status;
        t.loadStats();
        t.loadData();
    },
    methods: {

        buildParams() {
            let p = {};
            if (this.mode == 'seller') {
                p.guider_id = this.U.id;
            }

            return p;

        },
        loadStats() {

            let t = this;

            let p = t.buildParams();

            axios.post('user/order/stats', p).then(res => {

                if (res.data.status == 1) {
                    t.stats = res.data.options.stats;
                }

            });

        },

        loadData() {

            let t = this;

            let p = t.buildParams();

            p.status = t.status;

            axios.get('user/order', { params: p }).then(res => {

                if (res.data.status == 1) {

                    let c = S.get('order_state') || {};

                    let orders = res.data.options.items;

                    orders.forEach(order => {

                        let state = c[order.id] ? true : false;

                        if (state) {
                            t.showExpressPaths(order);
                        }
                        else
                            order.showExpressPaths = false;

                    });

                    t.list.order = orders;
                }
            });

        },

        setStatus(_s) {

            if (_s != this.status) {
                this.status = _s;
                this.loadData();
            }

        },

        showApplyReturn(order) {
            this.order = order;
            this.openModal('apply-return');
        },

        showExpressPaths(order) {

            let t = this;

            if (order.express_paths) {

                if (new Date().valueOf() >= new Date(order.last_checked_at).valueOf() + (1000 * 60 * 30))
                    t.loadExpressPath(order);
                else {
                    if (typeof order.express_paths == "string")
                        order.express_paths = JSON.parse(order.express_paths);
                }

                order.showExpressPaths = true;
                t.saveState(order, true);

            }
            else
                t.loadExpressPath(order);

        },

        loadExpressPath(order) {

            let t = this;

            if (t.busying)
                return;

            t.busying = true;

            axios.get('user/getExpressPaths/' + order.id).then(res => {

                t.busying = false;

                if (res.data.status == 1) {
                    order.showExpressPaths = true;
                    order.express_paths = res.data.options.express_paths;

                    t.saveState(order, true);
                }
            });
        },

        hideExpressPaths(order) {
            order.showExpressPaths = false;
            this.saveState(order, false);
        },

        saveState(order, expanded) {
            let c = S.get('order_state') || {};

            if (expanded)
                c[order.id] = expanded;
            else
                delete c[order.id];

            S.set('order_state', c);
        },

        cancelOrder(order) {

            let t = this;

            if (t.busying)
                return;

            t.busying = true;

            A.safety('确定要取消该订单吗？', 'warning').then(res => {
                if (res.isConfirmed) {
                    axios.get('user/cancelOrder/' + order.id).then(res => {
                        t.busying = false;

                        if (res.data.status == 1) {
                            t.list.order.remove(order);
                            t.loadStats();
                        }
                    });
                }
                else
                    t.busying = false;
            });
        },

        openModal(name) {
            this.$parent.disabledScroll = true;
            this.modal = name;
        },

        closeModal() {
            this.modal = '';
            this.$parent.disabledScroll = false;
        },

        applyReturn() {
            let t = this;
            let f = t.returnForm;

            if (!f.return_reason || f.return_reason.length < 10) {
                t.errMsg = "请至少输入10个字的问题描述"
                return;
            }

            t.errMsg = "";

            f.id = t.order.id;

            if (t.busying)
                return;

            t.busying = true;

            axios.post('user/applyReturn', f).then(res => {

                t.busying = false;

                if (res.data.status == 1) {
                    t.list.order.remove(t.order);
                    t.loadStats();
                    t.closeModal();
                    A.show('我们会尽快与您联系，谢谢！', '申请已提交', 'success');
                    t.status = 'returning';
                    t.loadData();
                }
            });
        },

        cancelReturn(order) {
            let t = this;

            if (t.busying)
                return;

            t.busying = true;

            A.safety('确定要取消售后申请吗？', 'warning').then(res => {
                if (res.isConfirmed) {
                    axios.get('user/cancelReturn/' + order.id).then(res => {
                        t.busying = false;

                        if (res.data.status == 1) {
                            t.list.order.remove(order);
                            t.loadStats();
                        }
                    });
                }
                else
                    t.busying = false;
            });
        },

        goOrder(order) {
            this.$router.push('/order?id=' + order.id);
        },

        setRole(role) {
            let t = this;

            if (t.mode != role) {

                t.mode = role;

                t.list.order = [];

                if (role == 'seller') {

                }
                else if (role == 'customer') {

                }

                t.loadStats();
                t.loadData();
            }
        }
    },
    data() {
        return {
            mode: 'customer',
            order: null,
            modal: '',
            status: 'handling',
            errMsg: '',
            returnForm: {
                return_type: 1,
                return_reason: ''
            },
            stats: {
                handling: 0,
                completed: 0,
                returning: 0,
            },
            list: {
                order: [],
                returnType: [
                    { 'id': 1, 'name': '7天无理由退货' },
                    { 'id': 2, 'name': '未收到货' },
                    { 'id': 3, 'name': '包裹破损' },
                    { 'id': 4, 'name': '质量问题' },
                    { 'id': 5, 'name': '其他' },
                ]
            },
            dict: {
                status: {
                    1: "待支付",
                    2: "已支付",
                    3: "已发货",
                    4: "已完成",
                },
                returnStatus: {
                    1: "售后中",
                    2: "已完结",
                },
            }
        };
    },
};
