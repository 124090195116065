

export default {
    name: "order",
    created() {
        let t = this;
        t.loadData();
    },

    methods: {
        loadData() {
            let t = this;

            axios.get('user/order/' + getURLParams().id).then(res => {
                if (res.data.status == 1) {
                    let order = res.data.options.item;;
                    if (order.address)
                        t.address = JSON.parse(order.address);
                    order.showExpressPaths = false;
                    t.loadExpressPath(order);
                    t.order = order;
                    if ((new Date() - new Date(order.created_at)) / (1000 * 3600) < 30)
                        t.enableModify = true;
                }
            });

        },

        loadExpressPath(order) {

            let t = this;

            if (t.busying)
                return;

            t.busying = true;

            axios.get('user/getExpressPaths/' + order.id).then(res => {

                t.busying = false;

                if (res.data.status == 1) {
                    order.showExpressPaths = true;
                    order.express_paths = res.data.options.express_paths;
                }
            });
        },


        showApplyReturn(order) {
            this.order = order;
            this.openModal('apply-return');
        },

        cancelOrder(order) {

            let t = this;

            if (t.busying)
                return;

            t.busying = true;

            A.safety('确定要取消该订单吗？', 'warning').then(res => {

                if (res.isConfirmed) {
                    axios.get('user/removeOrder/' + order.id).then(res => {
                        t.busying = false;

                        if (res.data.status == 1) {
                            t.$router.replace('/orders');
                        }
                    });
                }
            });
        },

        openModal(name) {
            this.$parent.disabledScroll = true;
            this.modal = name;
        },

        closeModal() {
            this.modal = '';
            this.$parent.disabledScroll = false;
        },

        applyReturn() {
            let t = this;
            let f = t.returnForm;

            if (!f.return_reason || f.return_reason.length < 10) {
                t.errMsg = "请至少输入10个字的问题描述"
                return;
            }

            t.errMsg = "";

            f.id = t.order.id;

            if (t.busying)
                return;

            t.busying = true;

            axios.post('user/applyReturn', f).then(res => {

                t.busying = false;

                if (res.data.status == 1) {
                    t.list.order.remove(t.order);
                    t.loadStats();
                    t.closeModal();
                    A.show('我们会尽快与您联系，谢谢！', '申请已提交', 'success');
                }
            });
        },

        cancelReturn(order) {
            let t = this;

            if (t.busying)
                return;

            t.busying = true;

            A.safety('确定要取消售后申请吗？', 'warning').then(res => {

                if (res.isConfirmed) {
                    axios.get('user/cancelReturn/' + order.id).then(res => {
                        t.busying = false;
                        if (res.data.status == 1) {
                            order.return_status = null;
                        }
                    });
                }
            });
        },


        modifyAddress() {

            let t = this;

            t.address = null;

            B.addressPage = {
                from: 'order',
                onSelect: address => {
                    t.address = address;
                    A.show('收件地址已变更', '', 'success');
                }
            };

            this.$router.push('/address');
        },

        goCheckout() {
            let t = this;

            wx.miniProgram.navigateTo({
                url: '/pages/payment/payment?oid=' + t.order.id
            });

            t.startPollingForPaymentResult();
        },

        startPollingForPaymentResult() {

            let t = this;

            axios.post('user/order/status/' + t.order.id).then(res => {

                if (res.data.status == 1) {

                    if (res.data.options.item.status == 2)
                        t.$router.replace('/paymentsuccess?oid=' + t.order.id);
                    else
                        setTimeout(() => { t.startPollingForPaymentResult() }, 1000);

                }

            });

        },


    },
    data() {
        return {
            order: null,
            modal: '',
            errMsg: '',
            address: null,
            enableModify: false,
            returnForm: {
                return_type: 1,
                return_reason: ''
            },

            list: {
                returnType: [
                    { 'id': 1, 'name': '7天无理由退货' },
                    { 'id': 2, 'name': '未收到货' },
                    { 'id': 3, 'name': '包裹破损' },
                    { 'id': 4, 'name': '质量问题' },
                    { 'id': 5, 'name': '其他' },
                ]
            }, dict: {
                status: {
                    1: "待支付",
                    2: "已支付",
                    3: "已发货",
                    4: "已完成",
                },
                returnStatus: {
                    1: "售后中",
                    2: "已完结",
                },
            }
        };
    },
};
