

export default {
  name: "Goods",
  props: {
    g: {
      type: Object,
      required: true,
    },
    search: {
      type: Object,
      required: false,
      default: ()=>{
        return {}
      }
    }
  },
  mounted() { },
  created() {
    let t = this;
  },
  activated: function (el) {
  },
  deactivated() {
  },
  methods: {
    goGoods(g) {
      this.$router.push('goods?id=' + g.id);
    }
  },
  data() {
    return {
      ready: false,
    };
  },
};
